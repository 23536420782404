import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import { ConnectWallet, ThirdwebNftMedia, useAddress, useContract, useContractRead, useOwnedNFTs, useTokenBalance, Web3Button, lightTheme } from "@thirdweb-dev/react";
import { BigNumber, ethers } from "ethers";
import NFTCard10 from "../components/NFTCard10";
import { nftDropContractAddress, stakingContractAddress, tokenContractAddress } from '../components/constants/contractAddresses10';
import ErrorMessagePopup from '../components/popups/ErrorMessagePopup';
import SuccessMessagePopup from '../components/popups/SuccessMessagePopup';

const ElfWaifus = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [claimableRewards, setClaimableRewards] = useState();
  const [numNFTsToWithdraw, setNumNFTsToWithdraw] = useState(0);
  const [numNFTsToStake, setNumNFTsToStake] = useState(0);

  const address = useAddress();

  const { contract: nftDropContract } = useContract(nftDropContractAddress, "nft-drop");
  const { contract: tokenContract } = useContract(tokenContractAddress, "token");
  const { contract, isLoading } = useContract(stakingContractAddress);
  const { data: ownedNfts } = useOwnedNFTs(nftDropContract, address);
  const { data: tokenBalance } = useTokenBalance(tokenContract, address);
  const { data: stakedTokens } = useContractRead(contract, "getStakeInfo", [address]);

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => setErrorMessage(""), 4000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => setSuccessMessage(""), 4000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  useEffect(() => {
    if (!contract || !address) return;

    const intervalId = setInterval(async () => {
      const stakeInfo = await contract.call("getStakeInfo", [address]);
      setClaimableRewards(stakeInfo[1]);
    }, 3000); // Actualizar cada 3 segundos

    // Limpiar el intervalo cuando el componente se desmonte o cambie la dirección
    return () => clearInterval(intervalId);
  }, [address, contract]); // Dependencias para que se ejecute cuando cambien address o contract


  const handleConnectWallet = async () => {
    try {
      setSuccessMessage("Goblin Minted");
    } catch (error) {
      setErrorMessage("Error: Transaction rejected or insufficient funds.");
    }
  };

  const stakeNft = async (id) => {
    if (!address) return;

    const isApproved = await nftDropContract.isApproved(address, stakingContractAddress);
    if (!isApproved) {
      await nftDropContract.setApprovalForAll(stakingContractAddress, true);
    }
    await contract.call("stake", [[id]]);
  };

  const stakeAllNfts = async () => {
    if (!address || !ownedNfts) return;

    const unstackedNfts = ownedNfts.filter((nft) => !isNftStaked(nft.metadata.id));

    if (unstackedNfts.length === 0) {
      console.log("No NFTs to stake.");
      return;
    }

    const nftIds = unstackedNfts.map((nft) => nft.metadata.id);

    const isApproved = await nftDropContract.isApproved(address, stakingContractAddress);
    if (!isApproved) {
      await nftDropContract.setApprovalForAll(stakingContractAddress, true);
    }

    await contract.call("stake", [nftIds]);
  };

  const withdrawAllNfts = async () => {
    if (!address || !stakedTokens) return;

    if (stakedTokens[0]?.length === 0) {
      console.log("No NFTs to unstake.");
      return;
    }

    const nftIds = stakedTokens[0].map((stakedToken) => stakedToken.toString());

    await contract.call("withdraw", [nftIds]);
  };

  const withdrawNFTs = async (numNFTs) => {
    if (!address || !stakedTokens || stakedTokens[0]?.length === 0) {
      console.log("No NFTs to withdraw.");
      return;
    }

    const nftIds = stakedTokens[0].slice(0, numNFTs).map((stakedToken) => stakedToken.toString());

    await contract.call("withdraw", [nftIds]);
  };

  const stakeNFTs = async (numNFTs) => {
    if (!address || !ownedNfts) return;

    const unstackedNfts = ownedNfts.filter((nft) => !isNftStaked(nft.metadata.id));

    if (unstackedNfts.length === 0) {
      console.log("No NFTs to stake.");
      return;
    }

    const nftIds = unstackedNfts.slice(0, numNFTs).map((nft) => nft.metadata.id);

    const isApproved = await nftDropContract.isApproved(address, stakingContractAddress);
    if (!isApproved) {
      await nftDropContract.setApprovalForAll(stakingContractAddress, true);
    }

    await contract.call("stake", [nftIds]);
  };

  const isNftStaked = (nftId) => {
    return stakedTokens && stakedTokens[0]?.includes(BigNumber.from(nftId));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Layout title="Elf Waifus | Stake">
      <div style={{ paddingTop: '150px', paddingBottom: '150px' }}>
        <div className="container">
          {!address ? (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <ConnectWallet
                theme={lightTheme({
                  colors: {
                    modalBg: "#3E4249",
                    borderColor: "#282b2f",
                    separatorLine: "#282b2f",
                    secondaryText: "#c4c4c4",
                    primaryText: "#ffffff",
                    connectedButtonBg: "#3E4249",
                    primaryButtonBg: "#3E4249",
                    primaryButtonText: "#ffffff",
                    secondaryButtonHoverBg: "#530053",
                    connectedButtonBgHover: "#282b2f",
                    walletSelectorButtonHoverBg: "#282b2f",
                    secondaryButtonText: "#ffffff",
                    secondaryButtonBg: "#530053",
                  },
                })}
                modalTitle={"Evil Kongs"}
                switchToActiveChain={true}
                modalSize={"compact"}
                showThirdwebBranding={false}
                modalTitleIconUrl={"/400X400.png"}
              />
            </div>
          ) : (
            <>
              <div className="grid">
                <div className="tokenMedia">
                  <h4 style={{ fontSize: "15px", marginBottom: "5px", marginTop: "15px" }}>Rewards</h4>
                  <p>
                    <b>
                      {!claimableRewards
                        ? "Loading..."
                        : parseFloat(ethers.utils.formatUnits(claimableRewards, 18)).toFixed(3)}
                    </b> {tokenBalance?.symbol}
                  </p>
                  <h4 style={{ fontSize: "15px", marginBottom: "5px" }}>Balance</h4>
                  <p>
                    <b>{tokenBalance?.displayValue && parseFloat(tokenBalance?.displayValue).toFixed(3)}</b> {tokenBalance?.symbol}
                  </p>
                </div>
              </div>

              <div style={{ height: '10px' }} />
              {successMessage && <SuccessMessagePopup message={successMessage} onClose={() => setSuccessMessage('')} />}
              {errorMessage && <ErrorMessagePopup message={errorMessage} onClose={() => setErrorMessage('')} />}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '10px',
                  flexWrap: 'wrap', // Esto permite que los botones se envuelvan cuando la pantalla sea más pequeña
                  padding: '20px',
                }}
              >
                <Web3Button
                  theme={lightTheme({
                    colors: {
                      primaryButtonBg: "#3E4249",
                      primaryButtonText: "#ffffff",
                    },
                  })}
                  action={(contract) => contract.call("claimRewards")}
                  contractAddress={stakingContractAddress}
                  style={{ margin: "5px" }}
                  onSuccess={() => {
                    setSuccessMessage('EVIL Claimed');
                  }}
                  onError={() => {
                    setErrorMessage('Error: Transaction rejected or insufficient funds.');
                  }}
                >
                  Claim EPEPE
                </Web3Button>

                <Web3Button
                  theme={lightTheme({
                    colors: {
                      primaryButtonBg: "#3E4249",
                      primaryButtonText: "#ffffff",
                    },
                  })}
                  action={() => stakeAllNfts()}
                  contractAddress={stakingContractAddress}
                  style={{ margin: "5px" }}
                  onSuccess={() => {
                    setSuccessMessage('All NFTs has been staked');
                  }}
                  onError={() => {
                    setErrorMessage('Error: Transaction rejected or insufficient funds.');
                  }}
                >
                  Stake All
                </Web3Button>

                <Web3Button
                  theme={lightTheme({
                    colors: {
                      primaryButtonBg: "#3E4249",
                      primaryButtonText: "#ffffff",
                    },
                  })}
                  action={() => withdrawAllNfts()}
                  contractAddress={stakingContractAddress}
                  style={{ margin: "5px" }}
                  onSuccess={() => setSuccessMessage('All has been Unstaked')}
                  onError={() => {
                    setErrorMessage('Error: Transaction rejected or insufficient funds.');
                  }}
                >
                  Unstake All
                </Web3Button>
              </div>

              <hr className="divider" />

              <h2 style={{ fontSize: "20px", marginBottom: "20px", textAlign: "center" }}>Staked</h2>
              <div className="grid">
                {stakedTokens &&
                  stakedTokens[0]?.map((stakedToken) => (
                    <NFTCard10
                      tokenId={stakedToken.toNumber()}
                      key={stakedToken.toString()}
                    />
                  ))}
              </div>

              <hr className="divider" />

              <h2 style={{ fontSize: "20px", marginBottom: "20px", textAlign: "center" }}>Unstaked</h2>
              <div className="grid">
                {ownedNfts?.map((nft) => (
                  <div key={nft.metadata.id.toString()}>
                    <ThirdwebNftMedia
                      metadata={nft.metadata}
                      className="nftMedia"
                    />
                    <p style={{ textAlign: "center", marginTop: "10px" }}>{nft.metadata.name}</p>
                    <div style={{
                      marginTop: '10px', // Agrega margen superior si es necesario
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                      <Web3Button
                        theme={lightTheme({
                          colors: {
                            primaryButtonBg: "#3E4249",
                            primaryButtonText: "#ffffff",
                          },
                        })}
                        contractAddress={stakingContractAddress}
                        action={() => stakeNft(nft.metadata.id)}
                        onSuccess={() => {
                          setSuccessMessage('NFT Staked');
                        }}
                        onError={() => {
                          setErrorMessage('Error: Transaction rejected or insufficient funds.');
                        }}
                      >
                        Stake
                      </Web3Button>
                    </div>

                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default ElfWaifus;
