import React from "react";
import { Link } from "react-router-dom";

const TrendingCollection = () => {
  return (
    <section id="polygon" className="nft2-collections">
      <div className="container">
        <div className="text-center">
          <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
            <h2 className="mb-0">Stakeable Collections</h2>
          </div>
          <p>
            On Polygon Network
          </p>
        </div>
        <div className="row g-3">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/evil-frens" className="d-block">
                <img
                  src="assets/img/home2/evilfrens-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/evilfrens.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Frens</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/bored-kongs" className="d-block">
                <img
                  src="assets/img/home2/bkongs-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/bkongs.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Bored Kongs</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/dumplings-xo" className="d-block">
                <img
                  src="assets/img/home2/dumplings-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover "
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/dumplings.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Dumplings XO</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/evil-pepe" className="d-block">
                <img
                  src="assets/img/home2/evilpepe-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/evilpepe.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Pepe</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/evil-pop" className="d-block">
                <img
                  src="assets/img/home2/epop-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/epop.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Evil Pop</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/moon-minters" className="d-block">
                <img
                  src="assets/img/home2/moonminters-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/moonminters.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>MoonMinters</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/antichrist" className="d-block">
                <img
                  src="assets/img/home2/antichrist-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/antichrist.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Antichrist</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/goetia-girls" className="d-block">
                <img
                  src="assets/img/home2/goetia-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/goetia.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Goetia Girls</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingCollection;
