import {
  ThirdwebNftMedia,
  useContract,
  useNFT,
  Web3Button,
  lightTheme,
} from "@thirdweb-dev/react";
import {
  nftDropContractAddress,
  stakingContractAddress,
} from "./constants/contractAddresses6";
import React, { useEffect, useState } from "react";
import ErrorMessagePopup from './popups/ErrorMessagePopup';
import SuccessMessagePopup from './popups/SuccessMessagePopup';


const NFTCard6 = ({ tokenId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage('');
      }, 4000); // Cierra el mensaje de error después de 4 segundos

      return () => clearTimeout(timer); // Limpia el temporizador cuando el componente se desmonta o cuando el mensaje de error cambia
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
      }, 4000); // Cierra el mensaje de éxito después de 4 segundos

      return () => clearTimeout(timer); // Limpia el temporizador cuando el componente se desmonta o cuando el mensaje de éxito cambia
    }
  }, [successMessage]);

  const handleConnectWallet = async () => {
    try {
      // Tu lógica para conectar la billetera
      // Supongamos que aquí tienes una operación exitosa
      setSuccessMessage('Goblin Minted');
    } catch (error) {
      // Si ocurre un error
      setErrorMessage('Error: Transaction rejected or insufficient funds.');
      setSuccessMessage(''); // Asegúrate de borrar cualquier mensaje de éxito anterior
    }
  };

  const { contract } = useContract(nftDropContractAddress, "nft-drop");
  const { data: nft } = useNFT(contract, tokenId);

  return (
    <>
      {nft && (
        <div className="nftBox">
          {nft.metadata && (
            <ThirdwebNftMedia
              metadata={nft.metadata}
              className="nftMedia"
            />
          )}
          <p style={{ textAlign: "center", marginTop: "10px" }}>{nft.metadata.name}</p>
          {successMessage && <SuccessMessagePopup message={successMessage} onClose={() => setSuccessMessage('')} />}
          {errorMessage && <ErrorMessagePopup message={errorMessage} onClose={() => setErrorMessage('')} />}
          <div style={{
            marginTop: '10px', // Agrega margen superior si es necesario
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Web3Button
              theme={lightTheme({
                colors: {
                  primaryButtonBg: "#3E4249",
                  primaryButtonText: "#ffffff",
                },
              })}
              action={(contract) => contract?.call("withdraw", [[nft.metadata.id]])}
              contractAddress={stakingContractAddress}
              onSuccess={() => setSuccessMessage('NFT Unstaked')}
              onError={() => setErrorMessage('Error: Transaction rejected or insufficient funds.')}
            >
              Withdraw
            </Web3Button>
          </div>
        </div>
      )}
    </>
  );
};
export default NFTCard6;