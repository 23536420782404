import {
  ThirdwebNftMedia,
  useContract,
  useNFT,
  Web3Button,
  lightTheme,
} from "@thirdweb-dev/react";
import React, { useEffect, useState } from "react";
import {
  nftDropContractAddress,
  stakingContractAddress,
} from "./constants/contractAddresses4";
import ErrorMessagePopup from "./popups/ErrorMessagePopup";
import SuccessMessagePopup from "./popups/SuccessMessagePopup";

const NFTCard4 = ({ tokenId }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        setSuccessMessage("");
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleConnectWallet = async () => {
    try {
      setSuccessMessage("Goblin Minted");
    } catch (error) {
      setErrorMessage("Error: Transaction rejected or insufficient funds.");
      setSuccessMessage("");
    }
  };

  const { contract } = useContract(nftDropContractAddress, "nft-drop");
  const { data: nft } = useNFT(contract, tokenId);

  return (
    <>
      {nft && (
        <div className="nftBox">
          {nft.metadata && (
            <ThirdwebNftMedia metadata={nft.metadata} className="nftMedia" />
          )}
          <p style={{ textAlign: "center", marginTop: "10px" }}>{nft.metadata.name}</p>
          {successMessage && (
            <SuccessMessagePopup
              message={successMessage}
              onClose={() => setSuccessMessage("")}
            />
          )}
          {errorMessage && (
            <ErrorMessagePopup
              message={errorMessage}
              onClose={() => setErrorMessage("")}
            />
          )}
          <div style={{
            marginTop: '10px', // Agrega margen superior si es necesario
            display: 'flex',
            justifyContent: 'center',
          }}>
            <Web3Button
              theme={lightTheme({
                colors: {
                  primaryButtonBg: "#3E4249",
                  primaryButtonText: "#ffffff",
                },
              })}
              action={(contract) =>
                contract?.call("withdraw", [[nft.metadata.id]])
              }
              contractAddress={stakingContractAddress}
              onSuccess={() => setSuccessMessage("NFT Unstaked")}
              onError={() =>
                setErrorMessage("Error: Transaction rejected or insufficient funds.")
              }
            >
              Withdraw
            </Web3Button>
          </div>
        </div>
      )}
    </>
  );
};
export default NFTCard4;
