import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Faqs from "../pages/Faqs";
import NotFound from "../pages/NotFound";
import HomeTwo from "../pages/home-two/HomeTwo";
import EvilFrens from "../pages/EvilFrens";
import BoredKongs from "../pages/BoredKongs";
import Dumplings from "../pages/Dumplings";
import EvilPepe from "../pages/EvilPepe";
import EvilPop from "../pages/EvilPop";
import MoonMinters from "../pages/MoonMinters";
import Antichrist from "../pages/Antichrist";
import GoetiaGirls from "../pages/GoetiaGirls";
import FoxyGirls from "../pages/FoxyGirls";
import CelestialAngels from "../pages/CelestialAngels";
import LilithMD from "../pages/LilithMD";
import ElfWaifus from "../pages/ElfWaifus";
import DemonDesire from "../pages/DemonDesire";
import BunnyLove from "../pages/BunnyLove";
import EvilSubmission from "../pages/Submission";
import Lust from "../pages/Lust";
import EroNuns from "../pages/EroNuns";
import EpepeTokenStake from "../pages/EpepeTokenStake";
import ScrollToTop from "../components/common/ScrollToTop";

const Routers = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomeTwo />} />
          <Route path="/evil-frens" element={<EvilFrens />} />
          <Route path="/bored-kongs" element={<BoredKongs />} />
          <Route path="/dumplings-xo" element={<Dumplings />} />
          <Route path="/evil-pepe" element={<EvilPepe />} />
          <Route path="/evil-pop" element={<EvilPop />} />
          <Route path="/moon-minters" element={<MoonMinters />} />
          <Route path="/antichrist" element={<Antichrist />} />
          <Route path="/goetia-girls" element={<GoetiaGirls />} />
          <Route path="/foxy-girls" element={<FoxyGirls />} />
          <Route path="/celestial-angels" element={<CelestialAngels />} />
          <Route path="/lilith-md" element={<LilithMD />} />
          <Route path="/elf-waifus" element={<ElfWaifus />} />
          <Route path="/demon-desire" element={<DemonDesire />} />
          <Route path="/bunny-love" element={<BunnyLove />} />
          <Route path="/evil-submission" element={<EvilSubmission />} />
          <Route path="/ero-nuns" element={<EroNuns />} />
          <Route path="/token-stake" element={<EpepeTokenStake />} />
          <Route path="/lust" element={<Lust />} />
          <Route path="/faq" element={<Faqs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Routers;
