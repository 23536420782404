import React, { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import {
    ConnectWallet,
    useAddress,
    useContract,
    useContractRead,
    useTokenBalance,
    Web3Button,
    lightTheme,
} from "@thirdweb-dev/react";
import { BigNumber, ethers } from "ethers";
import { stakingContractAddress } from "../components/constants/yourDetails";
import ErrorMessagePopup from '../components/popups/ErrorMessagePopup';
import SuccessMessagePopup from '../components/popups/SuccessMessagePopup';

const EpepeTokenStake = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const address = useAddress();
    const [amountToStake, setAmountToStake] = useState(0);

    // Initialize all the contracts
    const { contract: staking, isLoading: isStakingLoading } = useContract(
        stakingContractAddress,
        "custom"
    );

    // Get contract data from staking contract
    const { data: rewardTokenAddress } = useContractRead(staking, "rewardToken");
    const { data: stakingTokenAddress } = useContractRead(
        staking,
        "stakingToken"
    );

    // Initialize token contracts
    const { contract: stakingToken, isLoading: isStakingTokenLoading } =
        useContract(stakingTokenAddress, "token");
    const { contract: rewardToken, isLoading: isRewardTokenLoading } =
        useContract(rewardTokenAddress, "token");

    // Token balances
    const { data: stakingTokenBalance, refetch: refetchStakingTokenBalance } =
        useTokenBalance(stakingToken, address);
    const { data: rewardTokenBalance, refetch: refetchRewardTokenBalance } =
        useTokenBalance(rewardToken, address);

    // Get staking data
    const {
        data: stakeInfo,
        refetch: refetchStakingInfo,
        isLoading: isStakeInfoLoading,
    } = useContractRead(staking, "getStakeInfo", [address || "0"]);

    useEffect(() => {
        setInterval(() => {
            refetchData();
        }, 10000);
    }, []);

    const refetchData = () => {
        refetchRewardTokenBalance();
        refetchStakingTokenBalance();
        refetchStakingInfo();
    };

    return (
        <Layout title="$EPEPE | Stake">
            <section id="nft-raffle" className="hero-section-two position-relative overflow-hidden pt-80">
                <div className="text-center">
                    <div style={{ marginTop: "50px" }} className="title-style-2 text-center d-flex align-items-center justify-content-center">
                        <h2 className="mb-0">ERC-20 Staking - $EPEPE</h2>
                    </div>
                    <p>
                        On Polygon Network
                    </p>
                </div>
                <div style={{ marginBottom: "100px" }} className="container-fluid custom-container position-relative overflow-hidden">
                    <div className="row">
                        {!address ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <ConnectWallet
                                    theme={lightTheme({
                                        colors: {
                                            modalBg: "#3E4249",
                                            borderColor: "#282b2f",
                                            separatorLine: "#282b2f",
                                            secondaryText: "#c4c4c4",
                                            primaryText: "#ffffff",
                                            connectedButtonBg: "#3E4249",
                                            primaryButtonBg: "#3E4249",
                                            primaryButtonText: "#ffffff",
                                            secondaryButtonHoverBg: "#530053",
                                            connectedButtonBgHover: "#282b2f",
                                            walletSelectorButtonHoverBg: "#282b2f",
                                            secondaryButtonText: "#ffffff",
                                            secondaryButtonBg: "#530053",
                                        },
                                    })}
                                    modalTitle={"Evil Kongs"}
                                    switchToActiveChain={true}
                                    modalSize={"compact"}
                                    showThirdwebBranding={false}
                                    modalTitleIconUrl={"/400X400.png"}
                                />
                            </div>
                        ) : (
                            <>
                                <div className="col-md-6">
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img style={{ width: "50%" }} src="/assets/img/home2/Token.png" />
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                        }}
                                    >
                                        <p>Set amount to Stake/Unstake</p>
                                        <input
                                            type="number"
                                            value={amountToStake}
                                            onChange={(e) => setAmountToStake(parseFloat(e.target.value))}
                                            style={{ border: "1px solid grey", textAlign: "center", backgroundColor: "transparent", color: "white", borderRadius: "5px" }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '10px',
                                            flexWrap: 'wrap', // Esto permite que los botones se envuelvan cuando la pantalla sea más pequeña
                                            padding: '20px',
                                        }}
                                    >
                                        <Web3Button
                                            connectWallet={{
                                                btnTitle: "Connect Wallet",
                                                modalTitle: "Evil Kongs",
                                                modalSize: "compact",
                                                modalTitleIconUrl: "/400X400.png",
                                                showThirdwebBranding: false,
                                            }}
                                            theme={lightTheme({
                                                colors: {
                                                    modalBg: "#3E4249",
                                                    borderColor: "#282b2f",
                                                    separatorLine: "#282b2f",
                                                    secondaryText: "#c4c4c4",
                                                    primaryText: "#ffffff",
                                                    connectedButtonBg: "#3E4249",
                                                    primaryButtonBg: "#3E4249",
                                                    primaryButtonText: "#ffffff",
                                                    secondaryButtonHoverBg: "#530053",
                                                    connectedButtonBgHover: "#282b2f",
                                                    walletSelectorButtonHoverBg:
                                                        "#282b2f",
                                                    secondaryButtonText: "#ffffff",
                                                    secondaryButtonBg: "#530053",
                                                },
                                            })}
                                            contractAddress={stakingContractAddress}
                                            action={async (contract) => {
                                                if (stakingToken) {
                                                    await stakingToken.setAllowance(
                                                        stakingContractAddress,
                                                        amountToStake
                                                    );
                                                    await contract.call(
                                                        "stake",
                                                        [ethers.utils.parseEther(amountToStake.toString())]
                                                    );
                                                    alert("Tokens staked successfully!");
                                                }
                                            }}
                                        >
                                            Stake
                                        </Web3Button>

                                        <Web3Button
                                            connectWallet={{
                                                btnTitle: "Connect Wallet",
                                                modalTitle: "Evil Kongs",
                                                modalSize: "compact",
                                                modalTitleIconUrl: "/400X400.png",
                                                showThirdwebBranding: false,
                                            }}
                                            theme={lightTheme({
                                                colors: {
                                                    modalBg: "#3E4249",
                                                    borderColor: "#282b2f",
                                                    separatorLine: "#282b2f",
                                                    secondaryText: "#c4c4c4",
                                                    primaryText: "#ffffff",
                                                    connectedButtonBg: "#3E4249",
                                                    primaryButtonBg: "#3E4249",
                                                    primaryButtonText: "#ffffff",
                                                    secondaryButtonHoverBg: "#530053",
                                                    connectedButtonBgHover: "#282b2f",
                                                    walletSelectorButtonHoverBg:
                                                        "#282b2f",
                                                    secondaryButtonText: "#ffffff",
                                                    secondaryButtonBg: "#530053",
                                                },
                                            })}
                                            contractAddress={stakingContractAddress}
                                            action={async (contract) => {
                                                await contract.call(
                                                    "withdraw",
                                                    [ethers.utils.parseEther(amountToStake.toString())]
                                                );
                                                alert("Tokens unstaked successfully!");
                                            }}
                                        >
                                            Unstake
                                        </Web3Button>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="grid">
                                        <div className="tokenMedia">
                                            <h4 style={{ fontSize: "15px", marginBottom: "5px", marginTop: "15px" }}>Balance</h4>
                                            <p>{stakingTokenBalance && parseFloat(stakingTokenBalance.displayValue).toFixed(2)} EPEPE</p>
                                            <h4 style={{ fontSize: "15px", marginBottom: "5px" }}>Balance</h4>
                                            <p>{rewardTokenBalance && parseFloat(rewardTokenBalance.displayValue).toFixed(2)} WEPEPE</p>
                                        </div>
                                    </div>

                                    <div className="grid">
                                        <div className="tokenMedia">
                                            <h4 style={{ fontSize: "15px", marginBottom: "5px", marginTop: "15px" }}>Staked</h4>
                                            <p>
                                                {stakeInfo && parseFloat(ethers.utils.formatEther(stakeInfo[0].toString())).toFixed(2)} EPEPE
                                            </p>
                                            <h4 style={{ fontSize: "15px", marginBottom: "5px" }}>Rewards</h4>
                                            <p>
                                                {stakeInfo && parseFloat(ethers.utils.formatEther(stakeInfo[1].toString())).toFixed(2)} WEPEPE
                                            </p>
                                        </div>
                                    </div>

                                    <div style={{ height: '10px' }} />
                                    {successMessage && <SuccessMessagePopup message={successMessage} onClose={() => setSuccessMessage('')} />}
                                    {errorMessage && <ErrorMessagePopup message={errorMessage} onClose={() => setErrorMessage('')} />}
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '10px',
                                            flexWrap: 'wrap', // Esto permite que los botones se envuelvan cuando la pantalla sea más pequeña
                                            padding: '20px',
                                        }}
                                    >
                                        <Web3Button
                                            connectWallet={{
                                                btnTitle: "Connect Wallet",
                                                modalTitle: "Evil Kongs",
                                                modalSize: "compact",
                                                modalTitleIconUrl: "/400X400.png",
                                                showThirdwebBranding: false,
                                            }}
                                            theme={lightTheme({
                                                colors: {
                                                    modalBg: "#3E4249",
                                                    borderColor: "#282b2f",
                                                    separatorLine: "#282b2f",
                                                    secondaryText: "#c4c4c4",
                                                    primaryText: "#ffffff",
                                                    connectedButtonBg: "#3E4249",
                                                    primaryButtonBg: "#3E4249",
                                                    primaryButtonText: "#ffffff",
                                                    secondaryButtonHoverBg: "#530053",
                                                    connectedButtonBgHover: "#282b2f",
                                                    walletSelectorButtonHoverBg:
                                                        "#282b2f",
                                                    secondaryButtonText: "#ffffff",
                                                    secondaryButtonBg: "#530053",
                                                },
                                            })}
                                            contractAddress={stakingContractAddress}
                                            action={async (contract) => {
                                                await contract.call("claimRewards", []);
                                                alert("Rewards claimed successfully!");
                                            }}
                                        >
                                            Claim rewards
                                        </Web3Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default EpepeTokenStake;
