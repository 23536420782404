import React from "react";
import VideoBlog from "../../components/blogs/VideoBlog";
import BrandOne from "../../components/brands/BrandOne";
import TrendingCollection from "../../components/collections/TrendingCollection";
import NewsLetter from "../../components/cta/NewsLetter";
import Layout from "../../components/layout/Layout";
import HeroTwo from "./HeroTwo";
import TrendingCollection2 from "../../components/collections/TrendingCollection2";
import TokenStake from "../../components/collections/TokenStake";

const HomeTwo = () => {
  return (
    <Layout>
      <HeroTwo />
      <TrendingCollection />
      <TrendingCollection2 />
      <TokenStake />
      <NewsLetter />
      <BrandOne />
      <VideoBlog />
    </Layout>
  );
};

export default HomeTwo;
