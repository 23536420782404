import React from "react";
import { Link } from "react-router-dom";

const TrendingCollection2 = () => {
  return (
    <section id="polygon" className="nft2-collections">
      <div className="container">
        <div className="text-center">
          <div className="title-style-2 text-center d-flex align-items-center justify-content-center">
            <h2 className="mb-0">+18 Stakeable Collections</h2>
          </div>
          <p>
            On Polygon Network
          </p>
        </div>
        <div className="row g-3">
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/foxy-girls" className="d-block">
                <img
                  src="assets/img/home2/foxygirls-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/foxygirls-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Foxy Girls</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/celestial-angels" className="d-block">
                <img
                  src="assets/img/home2/cean-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/cean-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Celestial Angels</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/lilith-md" className="d-block">
                <img
                  src="assets/img/home2/lilith-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover "
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/lilith-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Lilith MD</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/elf-waifus" className="d-block">
                <img
                  src="assets/img/home2/elfwaifus-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/elfwaifus-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Elf Waifus</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/demon-desire" className="d-block">
                <img
                  src="assets/img/home2/desire-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/desire-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Demon Desire</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/bunny-love" className="d-block">
                <img
                  src="assets/img/home2/bunny-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/bunny-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Bunny Love</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/evil-submission" className="d-block">
                <img
                  src="assets/img/home2/submission-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/submission-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Submission</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/lust" className="d-block">
                <img
                  src="assets/img/home2/lust-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/lust-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Lust</h6>
                </div>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6">
            <div className="nft2-collection-column">
              <Link to="/ero-nuns" className="d-block">
                <img
                  src="assets/img/home2/eronuns-cover.png"
                  className="img-fluid rounded-top position-relative"
                  alt="cover"
                />
                <div className="profile-wrapper">
                  <img
                    src="assets/img/home2/eronuns-logo.png"
                    className="img-fluid"
                    alt="profile"
                  />
                </div>
                <div className="item-bottom">
                  <h6>Ero Nuns</h6>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrendingCollection2;
